<template>
  <main>
    <header
      class="page-header page-header-dark bg-gradient-primary-to-secondary pb-10"
    >
      <div class="container-fluid">
        <div class="page-header-content pt-4">
          <div class="row align-items-center justify-content-between">
            <div class="col-auto mt-4">
              <h1 class="page-header-title">
                <div class="page-header-icon">
                  <i class="fas fa-edit"></i>
                </div>
                Editar EPS
              </h1>
              <div class="page-header-subtitle"></div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="container-fluid mt-n10">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-4 offset-4">
              <form>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Codigo</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.IDEPS"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>NIT</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.NIT"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Nombre</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.DESEPS"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Departamento</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="departamento_seleccionado"
                      @change="getMunicipiosPorDepartamento()"
                    >
                      <option
                        v-for="departamento in departamentos"
                        :key="departamento.id"
                        :value="departamento.id"
                      >
                        {{ departamento.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Municipio</label>
                    <select
                      class="form-control form-control-sm"
                      v-model="eps.MUNICIPIO"
                    >
                      <option
                        v-for="municipio in municipios"
                        :key="municipio.id"
                        :value="municipio.id"
                      >
                        {{ municipio.nombre }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Direccion</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.DIRECCION"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Telefono</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.TELEFONO"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <label>Correo Electronico</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="eps.email"
                    />
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-12">
                    <button
                      type="button"
                      @click="actualizar()"
                      class="btn btn-success btn-sm btn-block shadow-sm"
                    >
                      <i class="fas fa-save"></i> Guardar Cambios
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import ServiceFactory from "./../../services/ServiceFactory";
import Swal from "sweetalert2/src/sweetalert2.js";

const EpsService = ServiceFactory.get("eps");
const LocationService = ServiceFactory.get("location");

export default {
  created() {
    this.id = this.$route.params.id;
    this.getEps();
  },
  data() {
    return {
      id: 0,
      eps: {},
      departamento_seleccionado: 0,
      departamentos: [],
      municipios: [],
    };
  },
  methods: {
    async getEps() {
      let loader = this.$loading.show({ color: "#0055ff", container: null });
      try {
        let response = await EpsService.get(this.id);
        this.eps = response.data;

        let departamentos_response = await LocationService.getDepartamentos();
        this.departamentos = departamentos_response.data;

        if (this.eps.municipio !== null) {
          this.departamento_seleccionado = this.eps.municipio.departamento.id;
          let municipios_response = await LocationService.getMunicipiosPorDepartamento(
            this.eps.municipio.departamento.id
          );
          this.municipios = municipios_response.data;
        }

        loader.hide();
      } catch (ex) {
        console.error(ex);
        loader.hide();
      }
    },
    async actualizar() {
      let loader = this.$loading.show({ color: "#0055ff", container: null });
      try {
        await EpsService.update(this.eps);
        loader.hide();
        Swal.fire({
          text: "Datos actualizados con exito",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
      } catch (ex) {
        console.error(ex);
        loader.hide();
        Swal.fire({
          text: "Ocurrio un error, por favor intente nuevamente",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    },
    async getMunicipiosPorDepartamento() {
      let loader = this.$loading.show({ color: "#0055ff", container: null });
      try {
        let municipios_response = await LocationService.getMunicipiosPorDepartamento(
          this.departamento_seleccionado
        );
        this.municipios = municipios_response.data;
        loader.hide();
      } catch (ex) {
        console.error(ex);
        loader.hide();
      }
    },
  },
};
</script>
