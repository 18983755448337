import Service from './Service';
import {isEmpty} from 'lodash';

const resource = '/api/servicio';

export default {
    getByCodigo(codigo, tarifa = '') {
        let url = `${resource}/get?by=codigo&s=${codigo}`;
        url += tarifa !== '' ? `&tarifa=${tarifa}` : '';
        return Service.get(url);
    },
    index(search = '', pagination = {}){

        let url = `${resource}?`;

        url += search !== '' ? `s=${search}` : '';

        url += (!isEmpty(pagination))
            ? `&paginate=true&per_page=${pagination.per_page}&page=${pagination.page}`
            : '';

        return Service.get(url);
    },
    search (query) {
        return Service.get(`${resource}/search?s=${query}`);
    },
    show(id){
        return Service.get(`${resource}/show/${id}`);
    },
    store(service){
        return Service.post(`${resource}/store`, service);
    },
    update(service){
        return Service.put(`${resource}/update`, service);
    },
    isUnique(code){
        return Service.get(`${resource}/is-unique/${code}`);
    }
}
